/* Magic that fixes iOS mobile's handling of absolute positioning */
html {
	position:fixed; 
	width:100%; 
	height:100%; 
	overflow:hidden;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.response-h1 {
	font-size: 32px;
	font-family: "Open Sans";
	font-weight: bold;
}

.response-h2 {
	font-size: 20px;
	font-family: "Open Sans";
	font-weight: bold;
}

.response-h3 {
	font-size: 18px;
	font-family: "Open Sans";
}

.response-h4 {
	font-size: 16px;
	font-family: "Open Sans";
	font-weight: bold;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
	scrollbar-width: thin;
	scrollbar-color: #f1f1f1 #888;
}
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
	width: 12px;
}
*::-webkit-scrollbar-track {
	background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 20px;
	border: 3px solid #f1f1f1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* This will stop zoom on inputs for ios*/
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
	font-size: 16px;
}

.dash-block {
	display: none;
	font-family: Open Sans;
	color: #202223;
	font-size: 16px;
	max-width:750px;
	position:relative;
  	background:#FFF;
	margin:30px 0;
  }
  .dash-block:nth-of-type(1) {
	margin-top:0;
  }
  .dash-block>img.hide {
	position:absolute;
	top:0;
	right:0;
	cursor:pointer;
  }
  .dash-card {
	padding:15px 20px;
	border:1px #EBEBEB solid;
	border-radius:5px;
	box-shadow: 0px 1px #DDD;
  }
  .dash-block h3 {
	font-size: 18px;
	font-weight: bold;
	line-height: 1.2;
	margin:10px 0px;
  }
  .dash-block p {
	line-height: 1.4;
  }
  .dash-block button {
	font-weight:bold;
	font-size:15px;
	padding:8px 18px;
	border-radius:3px;
	border:1px #888 solid;
	outline:none;
	background:#FFF;
  }
  .dash-block button.outline {
	margin-right:10px;
  }
  .dash-block button.btn-steps {
	font-weight:normal;
	display:block;
	margin:5px 0;
	min-width:215px;
	max-width:250px;
	text-align:left;
	padding-left:10px;
  }
  .dash-block button.btn-inactive {
	background:#CCC;
	color:#888;
	text-decoration: line-through;
	cursor:arrow;
  }
  .dash-block button.btn-active {
	background:#40c7a3;
	border:1px #339a7f solid;
	color:#FFF;
	text-shadow: 0px 1px #339a7f;
  }
  .dash-block a {
	color: #0056ab;
  }
  .dash-centered {
	text-align:center;
  }
  .dash-centered h2,
  .dash-centered h3,
  .dash-centered p {
	max-width:500px;
	margin-left: auto;
	margin-right: auto;
	display:block;
  }
  .dash-centered img.hero {
	margin:15px auto;
	max-width:175px;
	text-align:center;
	display:block;
  }
  .dash-fullwidth {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	gap: 4%;
	margin:10px 0;
  background:inherit;
  }
  .dash-fullwidth>.column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
	min-height:250px;
  }
  .dash-fullwidth>.column:nth-of-type(2) {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 0.6;
  }
  .dash-fullwidth h2 {
	font-size:17px;
	margin:0;
	color:#ff6517;
	text-transform:uppercase;
  }
  .dash-fullwidth h3 {
	font-size:20px;
	line-height:1.35;
	margin-bottom:0;
  }
  .dash-fullwidth p {
	margin:5px 0;
  }
  @media screen and (max-width: 650px) {
	.dash-fullwidth {
	flex-direction: column;
	}
  }